exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/blog/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-blog-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-price-js": () => import("./../../../src/pages/price.js" /* webpackChunkName: "component---src-pages-price-js" */),
  "component---src-pages-registration-index-js": () => import("./../../../src/pages/registration/index.js" /* webpackChunkName: "component---src-pages-registration-index-js" */),
  "component---src-pages-versions-js": () => import("./../../../src/pages/versions.js" /* webpackChunkName: "component---src-pages-versions-js" */),
  "component---src-pages-versions-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/versions/{markdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-versions-markdown-remark-frontmatter-slug-js" */)
}

